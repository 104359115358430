<template>
    <div class="px-3 py-2">
        <b-modal
            id="emp_deducts"
            ref="modalAdvanced"
            :title="$parent.lang.deduct_from_salary"
            size="md"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.deduct_from_salary }}
                    </div>
                </div>
            </template>
       
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.deducts_amount}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.deducts_amount"
                                    v-model="amount"
                                    :rules="fieldRules"
                                    >{{ amount }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="8" sm="12">
                                <label>{{$parent.lang.deducts_resone}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.deducts_resone"
                                    v-model="reward_resone"
                                    :rules="fieldRules"
                                    >{{ reward_resone }}</b-form-input>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </div>
            
            <template #modal-footer="{ close }" >
                    <div class="w-100" style="direction:rtl;text-align:center;">
                        <b-button type="button" @click='addit()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                        <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                    </div>
                </template>
            </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            empid: 0,
            amount: '',
            reward_resone: '',
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ]
        }
    },
    methods: {
        addit() {
            let vald = true;
            let message = '';
            if(this.amount == '' || this.amount <= 0){
                vald = false;
                message = this.$parent.lang.mor_than_zero;
            }
            else if(this.reward_resone == ''){
                vald = false;
                message = this.$parent.lang.required_field;
            }
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addReward");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[empid]",this.empid);
            post.append("data[amount]",this.amount);
            post.append("data[reward_resone]",this.reward_resone);
            post.append("data[type]",2);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                this.amount = '',
                this.reward_resone = "";   
                this.$parent.getEmployees();             
                document.getElementById('dhideExp').click();

            })
        }
    },
}
</script>